import { template as template_e0308cab4aea44d5b34012d86af88fac } from "@ember/template-compiler";
const FKText = template_e0308cab4aea44d5b34012d86af88fac(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
