import { template as template_8e97eb2a81a84d4bbb342526a231a53a } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_8e97eb2a81a84d4bbb342526a231a53a(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
