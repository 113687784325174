import { template as template_c0cbd35a9da746e3bbc915e2f1f2b666 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c0cbd35a9da746e3bbc915e2f1f2b666(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
