import { template as template_e29f75f55dfc43e7baddc124aba3e3b3 } from "@ember/template-compiler";
const FKLabel = template_e29f75f55dfc43e7baddc124aba3e3b3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
